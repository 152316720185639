import table from './table'

export default {
    bot: 'Bot',
    title: 'Bot bearbeiten',
    name: 'Name',
    class: 'Klasse',
    telegram_chat_id: 'Telegram Chat ID',
    enabled: 'Aktiviert',
    mt4_login: 'MT4 Login',
    mt4_password: 'MT4 Passwort',
    mt4_server: 'MT4 Server',
    mt4_active: 'MT4 Aktiv',

    providerId: 'Bot {name}',

    createBot: 'Create bot',
    deleteBot: 'Delete bot',
    deleteBotInfo:
        'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    noOrganisationFound: 'No organisation found.',
    botAlreadyExists: 'A login method with this bot already exists.',
    redirect_url: 'Redirect URL',

    bots: {
        google: 'Google',
        azure: 'Microsoft'
    },

    table
}