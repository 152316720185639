import { default as indexnsSNcCbuHAMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/auth/index.vue?macro=true";
import { default as indexLfZPHonBNRMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexrODTTAXNGAMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/index.vue?macro=true";
import { default as organisationBaseDatarFeNGHN1DVMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93SmeBjJzWVQMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/[id].vue?macro=true";
import { default as index7y8nO0uh2NMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/index.vue?macro=true";
import { default as organisationsBorNvJTIMjMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations.vue?macro=true";
import { default as dungeon1Yr0Hz9X9WMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputs6mJSPN8S6OMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexlUldSBDVkaMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index.vue?macro=true";
import { default as language8kUhyuXhepMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/language.vue?macro=true";
import { default as _91id_93Owtg6XWyROMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexKlrQcLFYQMMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93lz8tXABVOuMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexnhO4kWHMUEMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsUQW6pjs2lxMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings.vue?macro=true";
import { default as _91id_93bn97HyjmOqMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/bots/[id].vue?macro=true";
import { default as indexJ9sofmBrmMMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/bots/index.vue?macro=true";
import { default as messagesLYWBL0ROMbMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/messages.vue?macro=true";
import { default as ordersCdhsCHv1EGMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/orders.vue?macro=true";
import { default as userActivitiesrewdmnBOv2Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseData7aCUDt9o1WMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunications7IDwazezWSMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_939iVRy5cG83Meta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id].vue?macro=true";
import { default as indexLyf48qy2aVMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/index.vue?macro=true";
import { default as users7XGkgJ74MbMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93pJU3KXPq2aMeta } from "/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexnsSNcCbuHAMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexLfZPHonBNRMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationsBorNvJTIMjMeta?.name,
    path: "/organisations",
    meta: organisationsBorNvJTIMjMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93SmeBjJzWVQMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: index7y8nO0uh2NMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsUQW6pjs2lxMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93Owtg6XWyROMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexKlrQcLFYQMMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93lz8tXABVOuMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexnhO4kWHMUEMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "telegram-bots-id",
    path: "/telegram/bots/:id()",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/bots/[id].vue").then(m => m.default || m)
  },
  {
    name: "telegram-bots",
    path: "/telegram/bots",
    meta: indexJ9sofmBrmMMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/bots/index.vue").then(m => m.default || m)
  },
  {
    name: "telegram-messages",
    path: "/telegram/messages",
    meta: messagesLYWBL0ROMbMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/messages.vue").then(m => m.default || m)
  },
  {
    name: "telegram-orders",
    path: "/telegram/orders",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/telegram/orders.vue").then(m => m.default || m)
  },
  {
    name: users7XGkgJ74MbMeta?.name,
    path: "/users",
    meta: users7XGkgJ74MbMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_939iVRy5cG83Meta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseData7aCUDt9o1WMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexLyf48qy2aVMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93pJU3KXPq2aMeta || {},
    component: () => import("/home/forge/cryptoteam.farbcode.net/releases/20250327144642/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]