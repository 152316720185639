export default {
    id: 'ID',
    name: 'Name',
    telegram_chat_id: 'Telegram Chat ID',
    active: 'Aktiv',
    broker: 'Broker',
    mt4_active: 'MT4 Aktiv',
    mt4_status: 'MT4 Status',
    mt4_login: 'MT4 Login',
    mt4_account_balance: 'Balance',
}
